import React from 'react';

import HeaderLegal from '../components/HeaderLegal';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import img_gameLogo540 from '../assets/img/actionsquad_540x300.png';
import { Link } from 'gatsby';

export default () => (
  <Layout>
    <HeaderLegal />
    <section className="page-content bg-black" id="home">
      <div className="container">
        <div className="row align-items-center justify-content-center text-center">
          <div className="col-lg-10 align-self-end">
            <div className="centerlogo">
              <img src={img_gameLogo540} alt="Door Kickers: Action Squad" />
            </div>
            <h1 className="text-white font-weight-bold my-4 py-4">Credits</h1>
            <hr className="divider my-4" />
          </div>
          <div className="col-lg-10 text-justify text-muted text-center">
            <p className="text-white">Programming</p>
            <p>Mihai Dragomir</p>
            <p className="text-white">Game Design</p>
            <p>Dan Dimitrescu</p>
            <p className="text-white">Graphics</p>
            <p>
              Iulian Bogaciev
              <br />
              Adrian Cruceanu
              <br />
              Elias Frost
              <br />
              Christian Nordgren
            </p>
            <p className="text-white">Level Design</p>
            <p>
              Adrian Maraloiu
              <br />
              Mihai Raiciu
              <br />
              Tavi Petre
            </p>
            <p className="text-white">Net Programming &amp; Marketing</p>
            <p>
              Mihai Gosa
              <br />
              Catalin Saitan
            </p>
            <p className="text-white">Music &amp; SFX</p>
            <p>
              Gavin Harrison
              <br />
              George Vlad
            </p>
            <p className="text-white">Voice Acting</p>
            <p>
              Zachary Pill
              <br />
              Stephanos Rex
              <br />
              Tamara Ryan
              <br />
              Samantha Vega
              <br />
              Micah Blakeslee
              <br />
              Fletcher Armstrong
              <br />
              Elaine Cashmore
              <br />
              Kelloggs VA
              <br />
              Walter Mack
              <br />
              George Vlad
              <br />
              Jenn Henry
              <br />
              Andy Mack
              <br />
              Josef Gagnier
            </p>
            <p className="text-white">Console Porting</p>
            <p>Sickhead Games</p>
            <p className="text-white">QA</p>
            <p>Whyttest</p>
            <p className="text-white">Business</p>
            <p>Andreea-Medvedovici Per</p>
            <p className="text-white">Special Thanks</p>
            <p>Oreste &quot;Axi0s&quot; Gamba</p>
            <hr className="divider my-4" />
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="align-items-center">
            <Link to="/" className="btn btn-primary btn-xl js-scroll-trigger">
              Go to homepage
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section bg-black" id="footer">
      <div className="container"></div>
    </section>

    <Footer />
  </Layout>
);
